import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { createPortal } from 'react-dom';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { isEqual } from 'app/utils/osLodash';

import CustomFlashMessage from 'app/components/CustomFlashMessage';
import { loader as queryLoader } from 'graphql.macro';
import { graphql } from '@apollo/client/react/hoc';
import Loader from 'react-loaders';
import { Route, Routes } from 'react-router-dom';

import Authenticate from 'app/services/Authenticate';
import EmptyLayout from 'app/components/Layouts/EmptyLayout';
import UnauthenticatedRoutes from 'app/routes/Unauthenticated';
import UserStatusView from 'app/components/UserStatusView';

import { setCurrentUserGraph } from 'app/actions/authentication';
import { setWorkspaceData } from 'app/actions/workspace';
import {
  getKeyValueFromLocalStorage,
  removeKeyFromLocalStorage,
  setKeyValueInLocalStorage,
} from 'app/components/SwitchProfile/quickSwitcherHelper';
import { isCareWorkspaceView } from 'app/utils/Workspace/generalHelper';
import { getPubnubSettings } from 'app/pubnub';
import { PubNubProvider } from 'pubnub-react';
import QuickSwitcher from 'app/routes/QuickSwitcher';
import { isMobileDevice } from 'app/utils/deviceHelper';
import AuthenticatedRoutes from 'app/routes/Authenticated';
const CURRENT_USER_QUERY = queryLoader('app/graphql/CurrentUser.gql');

class AppRoutes extends PureComponent {
  componentDidMount() {
    this.setCurrentUser();
  }

  isGuestUser() {
    return !this.props.currentUser.token;
  }

  componentDidUpdate(prevProps) {
    if (!this.isGuestUser() && !isEqual(this.props.data, prevProps.data))
      this.setCurrentUser();

    if (!isCareWorkspaceView() && !isMobileDevice()) {
      let pathname = this.props.location.pathname;
      let userInReduxState = this.props.currentUser.graph?.id?.toString();
      let primaryToken =
        Authenticate.getItem('token') ||
        Authenticate.getUserAuthValueFromCookie();

      let identifier = getKeyValueFromLocalStorage('identifier');
      let lock = getKeyValueFromLocalStorage('lockNav');
      let currentUserId = getKeyValueFromLocalStorage('currentUserId');
      let secondaryToken = getKeyValueFromLocalStorage('HTTP_SESSION_TOKEN');
      const isProfileSwitcherEnabled =
        this.props.workspace?.data?.profile_switcher_enabled;
      const isPasswordPresent =
        this.props.workspace?.data?.user_request?.password_present;

      if (getKeyValueFromLocalStorage('HTTP_SESSION_TOKEN') == null)
        setKeyValueInLocalStorage('HTTP_SESSION_TOKEN', primaryToken);

      if (
        lock == 'true' &&
        identifier != null &&
        isProfileSwitcherEnabled &&
        isPasswordPresent
      ) {
        if (
          pathname !== `/${identifier}/profile/switcher` &&
          pathname !== `/${identifier}/profile/reset_password` &&
          pathname !== `/${identifier}/logout` &&
          pathname !== '/logout' &&
          !pathname.includes('profile/switcher') &&
          !pathname.includes('profile/reset_password')
        ) {
          // profile switcher
          window.location.href = `${process.env.REACT_APP_FRONTEND_APP_URL}/${identifier}/profile/switcher`;
        }
      }

      if (
        currentUserId !== null &&
        currentUserId != userInReduxState &&
        pathname !== `/${identifier}/profile/switcher` &&
        userInReduxState != undefined
      ) {
        this.props.navigate(0);
      }

      if (primaryToken !== secondaryToken && pathname === '/launch') {
        window.location.href = `${process.env.REACT_APP_FRONTEND_APP_URL}/${identifier}/explore`;
      }

      removeKeyFromLocalStorage('currentUserId');
    }
  }

  setCurrentUser() {
    if (!this.isGuestUser()) {
      const { user, workspace } = this.props.data;
      if (user) {
        this.props.setCurrentUserGraph(user);
        this.props.setWorkspaceData(workspace);
      }
    }
  }

  renderRoutes() {
    if (this.props.currentUser.token) {
      if (
        this.props.currentUser.graph &&
        this.props.currentUser.ability?.user?.id
      ) {
        return (
          // <PubNubProvider
          // client={getPubnubSettings(this.props.currentUser.ability.user.id)}>
          // {
          isMobileDevice() ? (
            <AuthenticatedRoutes {...this.props} />
          ) : (
            <>
              <QuickSwitcher {...this.props} />
            </>
          )
          // }
          // </PubNubProvider>
        );
      } else if (!this.props.currentUser.requestServed) {
        return <Loader type='ball-spin-fade-loader' />;
      } else {
        return (
          <Routes>
            <Route element={<EmptyLayout />}>
              <Route path='/status/:type' exact element={<UserStatusView />} />
            </Route>
          </Routes>
        );
      }
    } else {
      return <UnauthenticatedRoutes />;
    }
  }

  render() {
    return (
      <div>
        {createPortal(<CustomFlashMessage />, document.body)}
        {this.renderRoutes()}
      </div>
    );
  }
}

AppRoutes = graphql(CURRENT_USER_QUERY, {
  skip: (props) => !props.currentUser.token,
  options: (props) => {
    const { token } = props.currentUser;
    return {
      fetchPolicy: 'network-only',
      variables: {
        token: token.toString(),
        workspaceIdentifier: props.location.pathname.split('/')[1] || '',
      },
    };
  },
})(AppRoutes);
AppRoutes = connect(
  ({ currentUser, workspace }) => ({ currentUser, workspace }),
  {
    setCurrentUserGraph,
    setWorkspaceData,
  },
)(AppRoutes);

AppRoutes = withRouter(AppRoutes);

export default AppRoutes;
